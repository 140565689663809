import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { Button } from 'Components';

import { toMoneyFormat } from 'Helpers';

import PaymentType from './PaymentType';

import { PaymentTypesProps } from './types';
import { InitializedPaymentType } from 'hooks/types';
import { toDepositFormat } from 'Helpers';

const useStyles = makeStyles((theme) => ({
  paymentTypesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentTypes: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginBottom: theme.spacing(2),
  },
  switchPaymentTypesButton: {
    alignSelf: 'flex-end',
  },
  moneyDistribution: {
    color: theme.palette.error.main,
  },
  successMoneyDistribution: {
    color: theme.palette.primary.main,
  },
  deposit: {
    margin: 0,
  },
}));

const PaymentTypes = (props: PaymentTypesProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { payment_types, clientDeposit } = props;
  const {
    paymentTypes,
    isPaymentTypeDisabled,
    isPaymentTypesSwitchable,
    onPaymentTypesSwitch,
    onPaymentAmountChange,
    onPaymentIsActiveToggle,
    onPaymentCashlessAgentChange,
    sumOfPaymentTypesAmount,
    price,
  } = payment_types;

  const isMoneyDistributedCorrectly = price === sumOfPaymentTypesAmount;

  const undistributedMoney =
    price > 0
      ? `${t(', left')}: ${toMoneyFormat(price - sumOfPaymentTypesAmount)}`
      : '';

  const moneyDistributionInfo =
    `${t('Distributed money')}: ${toMoneyFormat(sumOfPaymentTypesAmount)}` +
    (isMoneyDistributedCorrectly ? '' : undistributedMoney);

  const renderPaymentType = (paymentType: InitializedPaymentType) => {
    const isPaymentTypeDisplayed = paymentType.isDisplayed;
    const disabled = isPaymentTypeDisabled;

    return (
      isPaymentTypeDisplayed && (
        <PaymentType
          key={paymentType.type}
          paymentType={paymentType}
          disabled={disabled}
          onPaymentIsActiveToggle={onPaymentIsActiveToggle}
          onPaymentAmountChange={onPaymentAmountChange}
          onPaymentCashlessAgentChange={onPaymentCashlessAgentChange}
        />
      )
    );
  };

  const transferToCardPaymentType = paymentTypes.find(
    (paymentType) => paymentType.type === 'transfer_to_card'
  );

  const switchPaymentTypesButtonText = transferToCardPaymentType?.isDisplayed
    ? `<= ${t('To basic payment types')}`
    : `${t('Transfer to card')} =>`;

  const clientDepositInfo = `${t('Client deposit')}: ${
    toDepositFormat(clientDeposit) ?? ''
  }`;

  return (
    <div className={classes.paymentTypesWrapper}>
      {clientDeposit != undefined && (
        <p className={classes.deposit}>
          <b>{clientDepositInfo}</b>
        </p>
      )}
      <p
        className={cn({
          [classes.successMoneyDistribution]: isMoneyDistributedCorrectly,
          [classes.moneyDistribution]: !isMoneyDistributedCorrectly,
        })}
      >
        <b>{moneyDistributionInfo}</b>
      </p>
      <div className={classes.paymentTypes}>
        {paymentTypes.map(renderPaymentType)}
      </div>
      <Button
        variant="outlined"
        onClick={onPaymentTypesSwitch}
        disabled={!isPaymentTypesSwitchable}
        className={classes.switchPaymentTypesButton}
      >
        {switchPaymentTypesButtonText}
      </Button>
    </div>
  );
};

export default PaymentTypes;
