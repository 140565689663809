export const common = {
  en: {
    Create: 'Create',
    Update: 'Update',
    Close: 'Close',
    Actions: 'Actions',
    'Rows Per Page': 'Rows Per Page',
    "Can't be blank": "Can't be blank",
    Yes: 'Yes',
    No: 'No',
    'No Info': 'No Info',
    'Additional Filters': 'Additional Filters',
    Filter: 'Filter',
    'Loading...': 'Loading...',
    'Nothing Found': 'Nothing Found',
    'No Data Provided': 'No Data Provided',
    Cash: 'Cash',
    cash: 'Cash',
    Card: 'Card',
    'Terminal': 'Terminal',
    Online: 'Online',
    'Transfer to card': 'Transfer to card',
    'Deposit': 'Deposit',
    'Export CSV': 'Export CSV',
    From: 'From',
    Till: 'Till (Not Including)',
    Confirmation: 'Confirmation',
    Confirm: 'Confirm',
    Close: 'Close',
    'Select File': 'Select File',
    'Are You Sure?': 'Are You Sure?',
    'Selected services': 'Selected services',
    'Select services': 'Select services',
    'Select': 'Select',
    'Analytics': 'Analytics',
    'Edit Company': 'Edit Company',
    'Active Purchased services': 'Active purchased services by date',
    'Booking Analytics': 'Booking Analytics',
    'Price': 'Price',
    'Active': 'Active',
    'UAH': 'UAH',
    fileWeightMsg: 'File must be smaller than {{size}} MB',
    'Paid Bookings By Booking Dates': 'Paid Bookings By Booking Dates',
    'Paid Bookings By Payment Dates': 'Paid Bookings By Payment Dates',
    'Date': 'Date',
    'Count': 'Count',
    'Responses': 'Responses',
    'Select Location': 'Select Location',
    'Distributed money': 'Distributed money',
    'Money is distributed incorrectly': 'Money is distributed incorrectly',
    'Select payment type': 'Select payment type',
    ', left': ', left',
    'Select a payment card': 'Select a payment card',
    'Not enough deposit': 'Not enough deposit',
    'To basic payment types': 'To basic payment types',
    Total: 'Total',
    'Income Sources': 'Income sources',
    'Client deposit': 'Client deposit',
    'Deposit movements': 'Deposit movements',
    'Cancel order': 'Cancel order',
    'Cancel order to deposit': 'Cancel order to deposit',
    'Created At': 'Created At',
    Amount: 'Amount',
    in: 'In',
    out: 'Out',
    'to deposit': 'To deposit',
    'to client': 'To client',
    'Order ID': 'Order ID',
    User: 'User',
    Client: 'Client',
    'Certificate Code': 'Certificate Code',
    'Final Price': 'Final Price',
    'Price per payment type': 'Price per payment type',
    Cancel: 'Cancel',
    'Send back to': 'Send back to',
    'Purchase of a certificate': 'Purchase of a certificate',
    'Purchase from the administrator': 'Purchase from the administrator',
    'Online booking': 'Online booking'
  },
  uk: {
    Create: 'Створити',
    Update: 'Оновити',
    Close: 'Закрити',
    Actions: 'Дії',
    'Rows Per Page': 'Рядків На Сторінці',
    "Can't be blank": 'Поле не може бути порожнім',
    Yes: 'Так',
    No: 'Ні',
    'No Info': 'Не Вказано',
    'Additional Filters': 'Додаткові фільтри',
    Filter: 'Фільтрувати',
    'Loading...': 'Завантаження...',
    'Nothing Found': 'Нічого Не Знайдено',
    'No Data Provided': 'Дані Відсутні',
    Cash: 'Готівка',
    cash: 'Готівка',
    Card: 'Картка',
    Terminal: 'Термінал',
    Online: 'Онлайн',
    'Transfer to card': 'Переказ на картку',
    'Deposit': 'Депозит',
    'Export CSV': 'Експорт CSV',
    From: 'Починаючи з',
    Till: 'До (Не Включно)',
    Confirmation: 'Підтвердження',
    Confirm: 'Підтвердити',
    Close: 'Закрити',
    'Select File': 'Виберіть Файл',
    'Are You Sure?': 'Ви Впевнені?',
    'Selected services': 'Вибрані сервіси',
    'Select services': 'Вибрати сервіси',
    'Select': 'Вибрати',
    'Analytics': 'Аналітика',
    'Edit Company': 'Редагування Компанії',
    'Booking Analytics': 'Букінг Аналітика',
    'Active Purchased Services By Date': 'Активні куплені сервіси по даті',
    'Price': 'Ціна',
    'Active': 'Активний',
    'UAH': 'грн.',
    fileWeightMsg: 'Розмір файлу не повинен перевищувати {{size}} МБ',
    'Paid Bookings By Booking Dates': 'Сплачені бронювання по датах бронювання',
    'Paid Bookings By Payment Dates': 'Сплачені бронювання по датах платежів',
    'Date': 'Дата',
    'Count': 'Кількість',
    'Responses': 'Відповіді',
    'Select Location': 'Виберіть Локацію',
    'Distributed money': 'Розподілені кошти',
    'Money is distributed incorrectly': 'Кошти розподілені неправильно',
    'Select payment type': 'Оберіть тип оплати',
    ', left': ', залишилось',
    'Select a payment card': 'Оберіть платіжну картку',
    'Not enough deposit': 'Недостатньо коштів на депозиті',
    'To basic payment types': 'До основних типів оплати',
    Total: 'Разом',
    'Income Sources': 'Типи оплати',
    'Client deposit': 'Депозит клієнта',
    'Deposit movements': 'Рух депозитних коштів',
    'Cancel order': 'Скасувати замовлення',
    'Cancel order to deposit': 'Скасувати замовлення до депозиту',
    'Created At': 'Створено',
    Amount: 'Сума',
    in: 'Зарахування',
    out: 'Списання',
    'to deposit': 'На депозит',
    'to client': 'До клієнта',
    'Order ID': 'ID замовлення',
    User: 'Користувач',
    Client: 'Клієнт',
    'Certificate Code': 'Код сертифікату',
    'Final Price': 'Сума замовлення',
    'Price per payment type': 'Поділ за типом оплати',
    Cancel: 'Скасовано',
    'Send back to': 'Повернуто',
    'Purchase of a certificate': 'Придбання сертифікату',
    'Purchase from the administrator': 'Придбано адміністратором',
    'Online booking': 'Онлайн бронювання',
  },
};
