import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Button, ListActions, Tooltip } from 'Components';

const useStyles = makeStyles(() => ({
  assignBtn: {
    marginRight: '8px',
  },
}));

const SUCCESS_PAYMENT_STATUS = 'success'

export const ActionsCell = ({ item, onDelete, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const deleteTooltip = item.payment_status === SUCCESS_PAYMENT_STATUS ? t('Delete and refund to deposit') : t('Delete');

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={onClick}
        className={classes.assignBtn}
        disabled={!item.client_id}
      >
        {t('Assign Services')}
      </Button>
      <ListActions onDelete={onDelete} deleteTooltip={deleteTooltip} />
    </>
  );
};
