export const Clients = {
  en: {
    Name: 'Name',
    Birthday: 'Birthday',
    Phone: 'Phone',
    Email: 'Email',
    'Important Notes': 'Important Notes',
    'Create Client': 'Create Client',
    Actions: 'Actions',
    'Insurance signed': 'Insurance signed',
    'Created': 'Created',
    'Last Visit': 'Last Visit'
  },
  uk: {
    Name: "Ім'я",
    Birthday: 'Д.Н.',
    Phone: 'Телефон',
    Email: 'Пошта',
    'Important Notes': 'Нотатки',
    'Create Client': 'Створити Клієнта',
    Actions: 'Дії',
    'Insurance signed': 'Страховка підписана',
    'Created': 'Створений',
    'Last Visit': 'Поп. візит'
  },
};

export const ClientForm = {
  en: {
    ...Clients.en,
    'New Client': 'New Client',
    'Edit Client': 'Edit Client',
    'Update Client': 'Update Client',
    'Client Photo': 'Client Photo',
    'Client Signature': 'Client Signature',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Middle Name': 'Middle Name',
    Comments: 'Comments',
    'Created By': 'Created By',
    'Choose Client Photo File': 'Choose Client Photo File',
    'Choose Client Signature File': 'Choose Client Signature File',
    'print eng form': 'print eng form',
    'print uk form': 'print uk form',
  },
  uk: {
    ...Clients.uk,
    'New Client': 'Створення Клієнта',
    'Edit Client': 'Редагування Клієнта',
    'Update Client': 'Оновити Клієнта',
    'Client Photo': 'Фото Клієнта',
    'Client Signature': 'Підпис Клієнта',
    'First Name': "Ім'я",
    'Last Name': 'Прізвище',
    'Middle Name': 'По батькові',
    Comments: 'Коментарі',
    'Created By': 'Створено',
    'Choose Client Photo File': 'Виберіть Файл з Фото Клієнта',
    'Choose Client Signature File': 'Виберіть Файл з Фото Підпису Клієнта',
    'print eng form': 'Друк анкети ENG',
    'print uk form': 'Друк анкети УКР',
  },
};

export const ClientShow = {
  en: {
    ...Clients.en,
    'Top Rope': 'Top Rope',
    'Bottom Rope': 'Bottom Rope',
    'Card Number': 'Card Number',
    'Card Numbers': 'Card Numbers',
    'Add Card Number': 'Add Card Number',
    Comments: 'Comments',
    'Buy New Service': 'Buy New Service',
    'Buy Service': 'Buy Service',
    ServicesCount: '{{count}} Services',
    'Use Certificate': 'Use Certificate',
    'Certificate Code': 'Certificate Code',
    'Certificate Already Used': 'Certificate Already Used',
    Cart: 'Cart',
    'Current Purchased Services': 'Current Purchased Services',
    'Current Active Purchased Services': 'Current Active Purchased Services',
    'Previously Purchased Services Without Discounts': 'Previously Purchased Services Without Discounts',
    'Current Active Discounts': 'Current Active Discounts',
    'Previously Purchased Discounts': 'Previously Purchased Discounts',
    'Discount Type': 'Discount Type',
    'Purchased Service Id': 'PS Id',
    Service: 'Service',
    'Service Type': 'S Type',
    'Buy Time': 'Buy Time',
    Expiration: 'Expiration',
    Balance: 'Balance',
    'Previously Purchased Services': 'Previously Purchased Services',
    'Service Usage History': 'Service Usage History',
    'Payment Types': 'Payment Types',
    UseServiceConfirmText:
      'Are you, sure, that you want use <b>{{service_name}}</b> \
      service for <b>{{client_name}}</b> ?',
    UseServiceWithTrainerConfirmText:
      'Are you, sure, that you want use \
      <b>{{service_name}}</b> service for <b>{{client_name}}</b> with trainer \
      <b>{{trainer_name}}</b> ?',
    CancelOrderConfirmText:
      'Are you sure that you want to cancel <b>{{order_id}}</b> \
      order for <b>{{client_name}}</b> ?',
    CancelOrderToDepositConfirmText:
      'Are you sure that you want to cancel <b>{{order_id}}</b> \
      order for <b>{{client_name}}</b> and return the money to the deposit?',
    CancelServiceUsageConfirmText:
      'Are you, sure, that you want cancel <b>{{service_name}}</b> \
      service usage for <b>{{client_name}}</b> ?',
    UseServiceText: 'Use {{service_id}}',
    UseServiceWithTrainerText: 'Use {{service_id}} with trainer',
    'Add trainers to system to use this service':
      'Add trainers to system to use this service',
    'Client Signature': 'Client Signature',
    "Cart can't be empty": "Cart can't be empty",
    Action: 'Action',
    'Use Time': 'Use Time',
    'Trainer': 'Trainer',
    'Location': 'Location',
    Expand: 'Expand',
    Total: 'Total',
    'Client Orders': 'Client Orders',
    'Order ID': 'Order ID',
    User: 'User',
    Price: 'Price',
    'Price With Discount': 'Price With Discount',
    'Sertificate has not found': 'Sertificate has not found',
    "You can't use this service": "You can't use this service",
    'Merge to': 'Merge to',
    'MergeClientText': 'Do you want to merge {{clientName}} to {{selectedClientName}}?',
    'Has club card': 'Has club card',
    'Use сertificate services': 'Use сertificate services',
    SertificateServiceInfo: 'Has been used: {{usage_count}} out of {{initial_count}}',
    'Send to client’s email': 'Send to client’s email',
    'Print': 'Print',
    'Frozen': 'Frozen',
    'Freeze service': 'Freeze service',
    'Freeze': 'Freeze',
    'Unfreeze': 'Unfreeze',
    'History of Freezing': 'History of Freezing',
    'Change Expiration Date': 'Change Expiration Date',
    'Order type': 'Type',
  },
  uk: {
    ...Clients.uk,
    'Top Rope': 'Верхня Страховка',
    'Bottom Rope': 'Нижня Страховка',
    'Card Number': 'Номер Картки',
    'Card Numbers': 'Номери Карток',
    'Add Card Number': 'Добавити Номер Картки',
    Comments: 'Коментарі',
    'Buy New Service': 'Купити Новий Сервіс',
    'Buy Service': 'Купити Сервіс',
    ServicesCount: '{{count}} Сервісів',
    'Use Certificate': 'Використати Сертифікат',
    'Certificate Code': 'Код Сертифікату',
    'Certificate Already Used': 'Сертифікат Вже Використаний',
    Cart: 'Кошик',
    'Current Purchased Services': 'Поточні куплені сервіси',
    'Current Active Purchased Services': 'Поточні активні куплені сервіси',
    'Previously Purchased Services Without Discounts': 'Попередні куплені сервіси(без знижок)',
    'Current Active Discounts': 'Поточні активні знижки',
    'Previously Purchased Discounts': 'Попередні знижки',
    'Discount Type': 'Тип Знижки',
    'Purchased Service Id': 'Id сервісу',
    Service: 'Сервіс',
    'Service Type': 'Тип сервісу',
    'Buy Time': 'Час оплати',
    Expiration: 'Закінчення',
    Balance: 'Баланс',
    'Previously Purchased Services': 'Попередні куплені сервіси',
    'Service Usage History': 'Історія використання сервісів',
    'Payment Types': 'Типи Платежу',
    UseServiceConfirmText:
      'Ви впевнені, що хочете використати <b>{{service_name}}</b> \
      сервіс для <b>{{client_name}}</b> ?',
    UseServiceWithTrainerConfirmText:
      'Ви впевнені, що хочете використати \
      <b>{{service_name}}</b> сервіс для <b>{{client_name}}</b> з тренером \
      <b>{{trainer_name}}</b> ?',
    CancelOrderConfirmText:
      'Ви впевнені, що хочете відмінити замовлення <b>{{order_id}}</b> \
      для <b>{{client_name}}</b> ?',
    CancelOrderToDepositConfirmText:
      'Ви впевнені, що хочете відмінити замовлення <b>{{order_id}}</b> \
      для <b>{{client_name}}</b> та повернути кошти на депозит?',
    CancelServiceUsageConfirmText:
      'Ви впевнені, що хочете відмінити \
      використання сервісу <b>{{service_name}}</b> для <b>{{client_name}}</b> ?',
    UseServiceText: 'Використати {{service_id}}',
    UseServiceWithTrainerText: 'Використати {{service_id}} з тренером',
    'Add trainers to system to use this service':
      'Добавте тренера в систему, щоб використати сервіс',
    'Client Signature': 'Підпис Клієнта',
    "Cart can't be empty": 'Кошик не може бути пустий',
    Action: 'Дія',
    'Use Time': 'Використовувався',
    'Trainer': 'Тренер',
    'Location': 'Локація',
    Expand: 'Продовжити',
    Total: 'Разом',
    'Client Orders': 'Замовлення клієнта',
    'Order ID': 'ID Замовлення',
    User: 'Користувач',
    Price: 'Ціна',
    'Price With Discount': 'Ціна з Знижкою',
    'Sertificate has not found': 'Сертифікат не знайдено',
    "You can't use this service": "Ви не можете використати цей сервіс",
    'Merge to': "Об'єднати з",
    'MergeClientText': "Ви хочете об'єднати {{clientName}} з {{selectedClientName}}? Всі дані будуть в клієнта {{selectedClientName}}",
    'Has club card': 'Є клубна картка',
    'Use сertificate services': 'Використати сервіси Сертифікату',
    SertificateServiceInfo: 'Було використано: {{usage_count}} із {{initial_count}}',
    'Send to client’s email': 'Надіслати на імейл клієнта',
    'Print': 'Роздрукувати',
    'Frozen': 'Заморожений',
    'Freeze service': 'Заморозити сервіс',
    'Freeze': 'Заморозити',
    'Unfreeze': 'Розморозити',
    'History of Freezing': 'Історія заморожень',
    'Change Expiration Date': 'Змінити дату закінчення',
    'Order type': 'Тип',
  },
};
