import { Grid, FormGroup, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  FormWrapper,
  Form,
  FormInput,
  DatePicker,
  ImageUpload,
  ResponsibilityForm,
  Button,
  Checkbox,
} from 'Components';
import { checkRequired } from 'Helpers/validation';
import { useForm } from 'Hooks';
import { SEND_DATES_FORMAT, DISPLAY_DATES_FORMAT, FORM_LANG, SUBDOMAINS_WITH_EN_FORM } from 'Constants';

const useStyles = makeStyles((theme) => ({
  printBtn: {
    color: '#fff',
    background: theme.palette.primary.light,
    width: '100%'
  },

}));

const validators = {
  first_name: checkRequired('first_name'),
  last_name: checkRequired('last_name'),
  phone: checkRequired('phone'),
};

export const ClientForm = ({ url, api_url, client, client_insurance_signature_included, subdomain }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    item,
    changeValue,
    action,
    method,
    isItemInvalid,
    validationMessagesToDisplay,
    setServerErrors,
    submit,
    submitted
  } = useForm(
    {
      ...client,
      ...(client.birthday && {
        birthday: moment(client.birthday, DISPLAY_DATES_FORMAT).format(
          SEND_DATES_FORMAT
        ),
      }),
    },
    validators,
    api_url
  );

  function handleChangeFirstName({ target }) {
    changeValue('first_name', target.value);
  }

  function handleChangeLastName({ target }) {
    changeValue('last_name', target.value);
  }

  function handleChangeMiddleName({ target }) {
    changeValue('middle_name', target.value);
  }

  function handleChangeBirthday({ target }) {
    changeValue('birthday', target.value || '');
  }

  function handleChangeEmail({ target }) {
    changeValue('email', target.value);
  }

  function handleChangePhone({ target }) {
    changeValue('phone', target.value);
  }

  function handleChangeImportantNotes({ target }) {
    changeValue('important_notes', target.value);
  }

  function handleChangeComments({ target }) {
    changeValue('comments', target.value);
  }

  function handleChangePhoto(photo) {
    changeValue('photo', photo);
  }

  function handleChangeInsuranceSigned({ target }) {
    changeValue('insurance_signed', target.checked);
  }

  const responsibilityForm = React.useRef(null);

  React.useEffect(() => {
    ReactDOM.render((<ResponsibilityForm ref={responsibilityForm} client={client} subdomain={subdomain} />),
      document.getElementById('form-of-responsibility'))
  }, []);

  function handlePrintEngForm() {
    responsibilityForm.current.print(FORM_LANG.en)
  }

  function handlePrintUkForm() {
    responsibilityForm.current.print(FORM_LANG.uk)
  }

  return (
    <Form
      name="client"
      action={action}
      method={method}
      validators={validators}
      disabled={isItemInvalid}
      onSubmit={submit}
      onError={setServerErrors}
      encType="multipart/form-data"
    >
      <FormWrapper title="Client" item={item} backUrl={url} disabled={submitted}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <ImageUpload
                  value={item.photo}
                  url={item.photo_url}
                  label={t('Choose Client Photo File')}
                  name="client[photo]"
                  onChange={handleChangePhoto}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <ImageUpload
                value={item.signature_photo}
                label={t('Choose Client Signature File')}
                url={item.signature_photo_url}
                name="client[signature_photo]"
                onChange={handleChangePhoto}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" className={classes.printBtn} onClick={handlePrintUkForm} disabled={!item.id}>
                {t('print uk form')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {SUBDOMAINS_WITH_EN_FORM.includes(subdomain) && 
                <Button variant="contained" className={classes.printBtn} onClick={handlePrintEngForm} disabled={!item.id}>
                  {t('print eng form')}
                </Button>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormInput
            name="client[first_name]"
            required
            validationMessages={validationMessagesToDisplay.first_name}
            value={item.first_name}
            label={t('First Name')}
            autoFocus
            onChange={handleChangeFirstName}
          />
          <FormInput
            name="client[last_name]"
            required
            value={item.last_name}
            validationMessages={validationMessagesToDisplay.last_name}
            label={t('Last Name')}
            onChange={handleChangeLastName}
          />
          <FormInput
            name="client[middle_name]"
            value={item.middle_name}
            label={t('Middle Name')}
            onChange={handleChangeMiddleName}
          />
          <DatePicker
            name="client[birthday]"
            onChange={handleChangeBirthday}
            value={item.birthday}
            label={t('Birthday')}
          />
          <FormInput
            name="client[phone]"
            required
            value={item.phone}
            validationMessages={validationMessagesToDisplay.phone}
            label={t('Phone')}
            onChange={handleChangePhone}
          />
          <FormInput
            name="client[email]"
            value={item.email}
            validationMessages={validationMessagesToDisplay.email}
            label={t('Email')}
            onChange={handleChangeEmail}
          />
          <FormInput
            name="client[important_notes]"
            value={item.important_notes}
            multiline
            label={t('Important Notes')}
            onChange={handleChangeImportantNotes}
          />
          <FormInput
            name="client[comments]"
            value={item.comments}
            multiline
            label={t('Comments')}
            onChange={handleChangeComments}
          />
          {!!client_insurance_signature_included && (
            <>
              <Checkbox
                checked={item.insurance_signed || false}
                onChange={handleChangeInsuranceSigned}
                label={t('Insurance signed')}
              />
              <input
                type={"hidden"}
                name="client[insurance_signed]"
                value={item.insurance_signed}
              />
            </>
          )}
          {!!item.created_by && (
            <div>
              {t('Created By')} {item.created_by}
            </div>
          )}
        </Grid>
      </FormWrapper>
    </Form>
  );
};

ClientForm.defaultProps = {
  client: {
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    phone: '',
    photo: '',
    birthday: '',
    important_notes: '',
    comments: '',
    phono: '',
    signature_photo: '',
    insurance_signed: false,
  },
};
