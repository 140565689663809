import { IconButton, } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip';
import confirmationStore from 'Helpers/confirmation';
import { useTranslation } from 'react-i18next';
export const ListActions = ({ children, editUrl, onDelete, deleteTooltip }) => {
  const { t } = useTranslation();

  function handleDelete() {
    confirmationStore.open(onDelete);
  }

  return (
    <>
      {children}
      {!!editUrl && (
        <IconButton size="small" href={editUrl}><i className="icon-edit"></i></IconButton>
      )}
      {!!onDelete && (
        <Tooltip title={deleteTooltip || t('Delete')} arrow>
          <IconButton size="small" onClick={handleDelete}>
            <i className="icon-trash-empty"></i>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
