export function toMoneyFormat(num) {
  const end = (num * 100 % 100 ? num * 100 % 100 : '00') + '₴';
  return Math.floor(num).toString().split('').reverse()
    .map((item, index) => { return (index + 1) % 3 === 0 ? `\u00a0${item}` : item })
    .reverse().join('') + `,${end}`;
  ;
}

export function toCardNumberFormat(value) {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
  const onlyNumbers = value.replace(/[^\d]/g, '')

  return onlyNumbers.replace(regex, (_, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' ')
  )
}

export function phoneFormatter(phone) {
  if (!/^\+38/.test(phone)) {
    phone = `+38${phone}`;
  }
  const match = phone.match(/^(\+38)(\d{3})(\d{3})(\d{2})(\d{2})$/);
  return !!match ? `${match[1]}(${match[2]})${match[3]}-${match[4]}-${match[5]}` : phone;
}

export function toPhoneNumberFormat(phoneNumber) {
  const regex = /(\d{3})(\d{3})(\d{2})(\d{2})/;

  return phoneNumber.replace(regex, '+38 ($1) $2 $3 $4');
}

export function toDepositFormat(deposit) {
  if(isNaN(deposit)) return 'Incorrect deposit value'
  // from the database we get a number in the format "1234567"
  // we need to convert it to the format "1 234 567"
  // the code below allows to do this
  const formattedDeposit = deposit
    .toString()
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(" ")
    .split("")
    .reverse()
    .join("")
  const postfix = deposit === 1 ? "GymCoin" : "GymCoins";

  return `${formattedDeposit} ${postfix}`;
}