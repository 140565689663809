import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';

import { ListWrapper, Table } from 'Components';
import { AJAX } from 'Helpers';
import confirmationStore from 'Helpers/confirmation';
import notificationsStore from 'Helpers/notifications';
import { CLIENT_PAGE_TABLES_PAGE_SIZES } from 'Constants';
import { usePagination } from 'Hooks';

import { useClientContext } from '../providers/ClientShowProvider';
import { CLIENT_QUERY_KEY, useServiceUsageHistoryQuery } from 'queries';
import {
  CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY,
  CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
  SERVICE_USAGE_HISTORY_QUERY_KEY,
  PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY
} from 'queries';
import { DEFAULT_SORT_PARAMS, PAGINATION_PARAMS } from '../ClientShow';

import { RemovedServiceType } from './type';

const ServiceUsageHistoryTable = () => {
  const { client, service_usage_histories_data_url } = useClientContext();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const paginationData = usePagination(PAGINATION_PARAMS, DEFAULT_SORT_PARAMS);
  const { paginationParams, handleChangePagination } = paginationData;

  const { data: usageHistoryServices, isLoading } = useServiceUsageHistoryQuery(
    {
      fetchUrl: service_usage_histories_data_url,
      paginationParams,
    }
  );

  const { data: services, records_total } = usageHistoryServices || {};

  const onServiceUseCancel = (removedService: RemovedServiceType) => {
    const { service_name, cancel_use_service_url } = removedService;

    return () => {
      const confirmText = t('CancelServiceUsageConfirmText', {
        service_name,
        client_name: `${client.first_name} ${client.last_name}`,
      });
      confirmationStore.open(async () => {
        const res = await AJAX.delete(cancel_use_service_url);
        if (res.status === 'error') {
          return notificationsStore.add({
            type: 'error',
            text: res.errors.join(', '),
          });
        }
        queryClient.invalidateQueries([SERVICE_USAGE_HISTORY_QUERY_KEY]);
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY]);
        queryClient.invalidateQueries([PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY]);
        queryClient.invalidateQueries([CLIENT_QUERY_KEY]);
      }, confirmText);
    };
  };

  const tableConfig = useMemo(
    function () {
      return [
        {
          title: t('Purchased Service Id'),
          accessor: 'purchased_service_id',
        },
        {
          title: t('Service'),
          accessor: 'service_name',
        },
        {
          title: t('Action'),
          accessor: 'action_type',
        },
        {
          title: t('Use Time'),
          accessor: 'use_time',
        },
        {
          title: t('Trainer'),
          accessor: 'trainer',
        },
        {
          title: t('Location'),
          accessor: 'location',
        },
        {
          title: t('Actions'),
          cellComponent: (removedService: RemovedServiceType) =>
            !!removedService.can_be_canceled && (
              <IconButton
                onClick={onServiceUseCancel(removedService)}
                size="small"
              >
                <i className="icon-block"></i>
              </IconButton>
            ),
        },
      ];
    },
    [i18n.language]
  );

  return (
    <ListWrapper title={t('Service Usage History')}>
      <Table
        config={tableConfig}
        pending={isLoading}
        data={services}
        rowsPerPageOptions={CLIENT_PAGE_TABLES_PAGE_SIZES}
        pagination={{ records_total, paginationParams, handleChangePagination }}
      />
    </ListWrapper>
  );
};

export default ServiceUsageHistoryTable;
