import { useRef, useMemo } from 'react';
import { IconButton, MenuItem, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { ListWrapper, Table, Button, ButtonWithMenu } from 'Components';
import { AJAX } from 'Helpers';
import { CLIENT_PAGE_TABLES_PAGE_SIZES } from 'Constants';
import confirmationStore from 'Helpers/confirmation';
import notificationsStore from 'Helpers/notifications';

import FreezeServiceDialog from '../FreezeServiceDialog';
import ExpirationDateDialog from '../ExpirationDateDialog';
import FreezingListDialog from '../FreezingListDialog';
import { useClientContext } from '../providers/ClientShowProvider';
import {
  CLIENT_DEPOSIT_MOVEMENTS_QUERY_KEY,
  CLIENT_QUERY_KEY,
  CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY,
  CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
  PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
  SERVICE_USAGE_HISTORY_QUERY_KEY,
} from 'queries';

import { CurrentPurchasedServicesProps, Trainer } from './types';
import { ServiceResponseData } from 'schemas';

const useStyles = makeStyles((theme) => ({
  activeService: {
    backgroundColor: theme.palette.success.main,
    '& td, & i': {
      color: theme.palette.common.white,
    },
  },
  activeDisabledService: {
    backgroundColor: theme.palette.success.light,
    '& td, & i': {
      color: theme.palette.common.white,
    },
  },
  notActiveService: {
    backgroundColor: theme.palette.grey[100],
  },
  frozenService: {
    backgroundColor: '#4c7baf',
    '& td, & i': {
      color: theme.palette.common.white,
    },
  },
  revertButton: {
    fontSize: '1rem',
  },
}));

const CurrentPurchasedServiceTable = (props: CurrentPurchasedServicesProps) => {
  const {
    title,
    showUseServiceColumn = true,
    recordsTotal,
    paginationParams,
    services,
    onChangePaginationParams,
    pending,
    freezeActions,
  } = props;
  const { trainers, api_client_url } = useClientContext();

  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const freezeServiceDialog = useRef<HTMLFormElement>(null);
  const freezingListDialog = useRef<HTMLFormElement>(null);
  const expirationDateDialog = useRef<HTMLFormElement>(null);

  const rowClassFn = (currentPurchasedService: ServiceResponseData) => {
    const { active, available_for_use, freeze } = currentPurchasedService;
    if (!!freeze) {
      return classes.frozenService;
    }
    if (active && available_for_use) {
      return classes.activeService;
    }
    if (active && !available_for_use) {
      return classes.activeDisabledService;
    }

    return classes.notActiveService;
  };

  const onServiceUse = (
    currentPurchasedService: ServiceResponseData,
    trainer: Trainer | string
  ) => {
    const { service_name, client_name, use_service_url } =
      currentPurchasedService;
    return () => {
      const localeKey = !!trainer
        ? 'UseServiceWithTrainerConfirmText'
        : 'UseServiceConfirmText';
      const confirmText = t(localeKey, {
        service_name,
        client_name,
        ...(typeof trainer === 'object' && {
          trainer_name: trainer.name,
        }),
      });

      confirmationStore.open(async () => {
        const res = await AJAX.post(use_service_url, {
          ...(typeof trainer === 'object' && {
            body: {
              trainer_id: trainer.id,
            },
          }),
        });
        if (res && res.status === 'error') {
          return notificationsStore.add({
            type: 'error',
            text: res.errors?.join(', '),
          });
        }
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([
          CURRENT_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([CURRENT_ACTIVE_DISCOUNTS_QUERY_KEY]);
        queryClient.invalidateQueries([
          PREVIOUSLY_PURCHASED_ACTIVE_SERVICES_QUERY_KEY,
        ]);
        queryClient.invalidateQueries([SERVICE_USAGE_HISTORY_QUERY_KEY]);
        queryClient.invalidateQueries([CLIENT_QUERY_KEY]);
        queryClient.invalidateQueries([CLIENT_DEPOSIT_MOVEMENTS_QUERY_KEY]);
      }, confirmText);
    };
  };

  const onRevertToBooking =
    (currentPurchasedService: ServiceResponseData) => () => {
      confirmationStore.open(async () => {
        const res = await AJAX.put(
          currentPurchasedService.revert_to_booking_url
        );

        if (res.flash_type == 'success') {
          queryClient.invalidateQueries();
        }
      });
    };

  const renderActions = (currentPurchasedService: ServiceResponseData) => {
    return (
      <>
        {!!currentPurchasedService.expiration_date && (
          <>
            <IconButton
              size="small"
              onClick={() =>
                freezeServiceDialog.current?.onOpen(currentPurchasedService)
              }
            >
              <i className="icon-freeze"></i>
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                freezingListDialog.current?.onOpen(
                  currentPurchasedService.freeze_periods,
                  currentPurchasedService.id
                )
              }
            >
              <i className="icon-list"></i>
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                expirationDateDialog.current?.onOpen(currentPurchasedService)
              }
            >
              <i className="icon-clock"></i>
            </IconButton>
          </>
        )}
        {!!currentPurchasedService.from_booking && (
          <IconButton
            className={classes.revertButton}
            size="small"
            onClick={onRevertToBooking(currentPurchasedService)}
          >
            <i className="icon-ccw"></i>
          </IconButton>
        )}
      </>
    );
  };

  const tableConfig = useMemo(() => {
    const columns = [
      {
        title: t('Purchased Service Id'),
        cellComponent: (currentPurchasedService: ServiceResponseData) => {
          if (!currentPurchasedService.available_for_use) {
            return t("You can't use this service");
          }

          if (!currentPurchasedService.assigned_to_trainer) {
            return (
              <Button onClick={onServiceUse(currentPurchasedService, '')}>
                {t('UseServiceText', {
                  service_id: currentPurchasedService.purchased_service_id,
                })}
              </Button>
            );
          }
          if (!!trainers && !!trainers.length) {
            return (
              <ButtonWithMenu
                trigger={
                  <Button>
                    {t('UseServiceWithTrainerText', {
                      service_id: currentPurchasedService.purchased_service_id,
                    })}
                  </Button>
                }
              >
                {trainers.map((trainer) => (
                  <MenuItem
                    key={trainer.id}
                    onClick={onServiceUse(currentPurchasedService, trainer)}
                  >
                    {trainer.name}
                  </MenuItem>
                ))}
              </ButtonWithMenu>
            );
          }
          return t('Add trainers to system to use this service');
        },
      },
      {
        title: t('Service'),
        accessor: 'service_name',
      },
      {
        title: t('Service Type'),
        accessor: 'service_type',
      },
      {
        title: t('Order ID'),
        accessor: 'client_user_order_id',
      },
      {
        title: t('Buy Time'),
        accessor: 'buy_time',
      },
      {
        title: t('Expiration'),
        accessor: 'expiration_date',
      },
      {
        title: t('Balance'),
        accessor: 'remaining_visits_count',
      },
      {
        title: t('Actions'),
        cellComponent: (currentPurchasedService: ServiceResponseData) =>
          renderActions(currentPurchasedService),
      },
    ];
    return showUseServiceColumn ? columns : columns.slice(1);
  }, [i18n.language, showUseServiceColumn]);

  return (
    <ListWrapper title={title}>
      <Table
        config={tableConfig}
        pending={pending}
        data={services}
        rowClassFn={rowClassFn}
        rowsPerPageOptions={CLIENT_PAGE_TABLES_PAGE_SIZES}
        pagination={{
          records_total: recordsTotal,
          paginationParams,
          handleChangePagination: onChangePaginationParams,
        }}
      />
      {!!freezeActions && (
        <>
          <FreezeServiceDialog
            ref={freezeServiceDialog}
            url={api_client_url}
          />
          <FreezingListDialog
            ref={freezingListDialog}
            url={api_client_url}
          />
          <ExpirationDateDialog
            ref={expirationDateDialog}
            url={api_client_url}
          />
        </>
      )}
    </ListWrapper>
  );
};

export default CurrentPurchasedServiceTable;