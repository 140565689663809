import { AJAX } from 'Helpers';

import {
  FetchDataParams,
  ServicesUsageHistoryResponseData,
  ClientOrdersResponseData,
  CurrentActiveDiscountsResponseData,
  FetchDepositMovementsType,
} from 'schemas';

export class HttpClient {
  static getClient(fetchUrl: string) {
    return AJAX.get(fetchUrl);
  }

  static getDiscounts({
    fetchUrl,
    paginationParams,
    filters,
  }: FetchDataParams): CurrentActiveDiscountsResponseData {
    return AJAX.get(fetchUrl, {
      body: {
        ...(paginationParams || {}),
        ...(filters && { filters }),
      },
    });
  }

  static getPurchasedServices({
    fetchUrl,
    paginationParams,
    filters,
  }: FetchDataParams): CurrentActiveDiscountsResponseData {
    return AJAX.get(fetchUrl, {
      body: {
        ...(paginationParams || {}),
        ...(filters && { filters }),
      },
    });
  }

  static getServiceUsageHistory({
    fetchUrl,
    paginationParams,
  }: FetchDataParams): ServicesUsageHistoryResponseData {
    return AJAX.get(fetchUrl, {
      body: {
        ...(paginationParams || {}),
      },
    });
  }

  static getClientOrders({
    fetchUrl,
    paginationParams,
  }: FetchDataParams): ClientOrdersResponseData {
    return AJAX.get(fetchUrl, {
      body: {
        ...(paginationParams || {}),
      },
    });
  }

  static getDepositMovements({
    fetchUrl,
    paginationParams,
  }: FetchDataParams): FetchDepositMovementsType {
    return AJAX.get(fetchUrl, {
      body: {
        ...(paginationParams || {}),
      },
    });
  }
}
