import {
  Dialog, DialogTitle, DialogContent, makeStyles, IconButton, DialogActions, Button,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AjaxAutocomplete, Form, FormInput } from 'Components';
import { useForm } from 'Hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: '52px',
    height: '52px',
  },
}));

function clientsRequestBody(value) {
  return {
    page: 1,
    size_per_page: 10,
    sort_column: 'created_at',
    sort_direction: 'desc',
    search: {
      value
    }
  }
}

function getOptionLabel(option) {
  return !!option ? `${option.first_name} ${option.last_name} (${option.birthday ? option.birthday : ' - '})` : '';
}

const DEFAULT_ITEM = {
  client: null,
  comment: ''
}

export const MemberDialog = React.forwardRef(({
  apiUrl, clients_api_url, onSubmit
}, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isOpen, changeIsOpen] = React.useState(false);

  const {
    item, changeValue, action, method, submit, setServerErrors, validationMessagesToDisplay, unsubmit,  submitted,
    setItem
  } = useForm(DEFAULT_ITEM, {}, apiUrl);

  const open = () => {
    setItem(DEFAULT_ITEM)
    changeIsOpen(true);
  }

  function close() {
    changeIsOpen(false);
  }

  function handleChangeClient(_, client) {
    changeValue('client', client)
  }

  function handleChangeComment({ target }) {
    changeValue('comment', target.value);
  }

  React.useImperativeHandle(ref, () => ({
    open,
  }));

  function onSuccessSubmit() {
    onSubmit()
    close()
  }

  const getSerializedItem = () => {
    const { client, comment } = item;

    return {
      client_id: client?.id,
      comment: comment
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth
    >
      <DialogTitle className={classes.root}>
        {t('Add member')}
        <IconButton
          size="medium"
          className={classes.closeButton}
          onClick={close}
        >
          <i className="icon-cancel"></i>
        </IconButton>
      </DialogTitle>
      <Form
        name="booking_member"
        action={action}
        method={method}
        onSubmit={submit}
        getSerializedItem={getSerializedItem}
        onSuccessSubmit={onSuccessSubmit}
        onError={setServerErrors}
        onRequestEnd={unsubmit}
      >
        <DialogContent>
          <AjaxAutocomplete
            label={t('Client')}
            value={item.client}
            getRequestBody={clientsRequestBody}
            searchUrl={clients_api_url}
            getOptionLabel={getOptionLabel}
            onChange={handleChangeClient}
            method='get'
          />

          <FormInput
            value={item.notes}
            label={t('Comment')}
            multiline
            onChange={handleChangeComment}
            validationMessages={validationMessagesToDisplay.comment}
          />
          {validationMessagesToDisplay.base && (
            <FormHelperText error>{validationMessagesToDisplay.base}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
          >
            {t('Add')}
          </Button>
        </DialogActions>
      </Form>
    </Dialog >
  );
})