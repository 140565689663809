import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Button } from 'Components';
import { Table } from 'Components';
import { useClientDepositMovementsQuery } from 'queries';
import { DEFAULT_SORT_PARAMS, PAGINATION_PARAMS } from '../../ClientShow';
import { usePagination } from 'Hooks';
import { CLIENT_PAGE_TABLES_PAGE_SIZES } from 'Constants';
import { DepositMovementType } from 'schemas';

const useStyles = makeStyles(() => ({
  listItem: {
    borderBottom: '1px solid black',
  },
  dialogTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const DepositMovements = ({
  apiUrl,
  isOpen,
  onClose,
}: {
  apiUrl: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const paginationData = usePagination(PAGINATION_PARAMS, DEFAULT_SORT_PARAMS);
  const { paginationParams, handleChangePagination } = paginationData;

  const { data: clientDepositMovementsData, isLoading } =
    useClientDepositMovementsQuery({
      fetchUrl: apiUrl,
      paginationParams,
    });

  const tableConfig = [
    {
      title: t('User'),
      accessor: 'user_name',
    },
    {
      title: t('Action'),
      accessor: 'action_type',
      cellComponent: ({ action_type }: DepositMovementType) => t(action_type),
    },
    {
      title: t('Amount'),
      accessor: 'amount',
    },
    {
      title: t('Location'),
      accessor: 'location',
    },
    {
      title: t('Created At'),
      accessor: 'created_at',
      sortable: true,
    },
    {
      title: t('Order ID'),
      accessor: 'order_id',
    },
    {
      title: t('Order type'),
      accessor: 'order_type',
      cellComponent: ({ order_type }: DepositMovementType) => t(order_type)
    },
  ];

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <h3>{t('Deposit movements')}</h3>
          <Button
            color="primary"
            onClick={onClose}
          >
            {t('Close')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <Table
          config={tableConfig}
          pending={isLoading}
          data={clientDepositMovementsData?.data}
          rowsPerPageOptions={CLIENT_PAGE_TABLES_PAGE_SIZES}
          pagination={{
            records_total: clientDepositMovementsData?.records_total,
            paginationParams,
            handleChangePagination,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
