import { FormEvent, useMemo, useRef, useState } from 'react';
import { Collapse, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import { Button, FormControl, Autocomplete } from 'Components';
import { AJAX, updateItemInArray } from 'Helpers';
import notificationsStore from 'Helpers/notifications';

import BuyServiceDialog from '../BuyServiceDialog';
import Certificate from './Certificate';
import CardNumbers from '../CardNumbers';
import { useClientContext } from '../providers/ClientShowProvider';
import { CLIENT_DEPOSIT_MOVEMENTS_QUERY_KEY, CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY } from 'queries';

import {
  BuyThingsStateTypes,
  CertificateType,
  MakeStyles,
  ServiceProps,
  
} from './types';

const useStyles = makeStyles((theme) => ({
  mainButtons: {
    marginTop: theme.spacing(1),
  },
  actionButton: {
    margin: theme.spacing(1),
  },
  servicesFormGroup: {
    width: '100%',
  },
  serviceNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  service: {
    color: theme.palette.primary.main,
  },
  overedService: {
    color: theme.palette.error.main,
  },
}));

const getAutocompleteOptionLabel = (certificate: CertificateType) =>
  certificate.code;

const isAutocompleteOptionSelected = (
  option: CertificateType,
  value: CertificateType
) => option.code === value.code;

const DEFAULT_STATE = {
  isOpen: false,
  certificate: {
    id: 0,
    code: '',
    comment: '',
    used: false,
    services: [],
    created_at: '',
  },
};

const getCertificatesRequestBody = (valueInput: string) => ({
  page: 1,
  size_per_page: 10,
  sort_column: 'created_at',
  sort_direction: 'asc',
  search: {
    value: valueInput.toUpperCase(),
  },
});

const BuyThings = () => {
  const buyProps = useClientContext();
  const { use_certificates_url, certificates_url, ...props } = buyProps;
  const buyServiceDialog = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const classes: MakeStyles = useStyles();
  const [state, setState] = useState<BuyThingsStateTypes>({
    isOpen: false,
    certificate: {
      id: 0,
      code: '',
      comment: '',
      used: false,
      services: [],
      created_at: '',
    },
  });

  const onOpenBuyServiceDialog = () => buyServiceDialog.current?.onOpen();

  const onOpen = () => setState({ ...state, isOpen: true });

  const onClose = () => setState(DEFAULT_STATE);

  const isFormDisabled = useMemo(
    () => state.certificate?.services.every(({ checked }) => !checked),
    [state.certificate]
  );

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!!isFormDisabled) {
      return;
    }

    const selectedServiceIds = state.certificate?.services
      .filter((service) => service.checked)
      .map((service) => service.id);

    const res = await AJAX.post(use_certificates_url, {
      body: {
        code: state.certificate?.code,
        client_id: props.client.id,
        service_ids: selectedServiceIds,
      },
    });

    if (res.status == 'error') {
      return notificationsStore.add({ type: 'error', text: res.errors });
    }
    queryClient.invalidateQueries([
      CURRENT_PURCHASED_NOT_ACTIVE_SERVICES_QUERY_KEY,
    ]);
    queryClient.invalidateQueries([
      CLIENT_DEPOSIT_MOVEMENTS_QUERY_KEY,
    ]);
    onClose();
  };

  const isServicesExists = !!props.services.length;

  const onAutoCompleteChange = (_: any, certificate: CertificateType) => {
    if (!certificate) {
      return setState({
        ...state,
        certificate: DEFAULT_STATE.certificate,
      });
    }

    setState({
      ...state,
      certificate: {
        ...certificate,
        services: certificate.services.map((serviceItem) => {
          return {
            ...serviceItem,
            checked: false,
          };
        }),
      },
    });
  };

  const onServiceCheck = (service: ServiceProps) => {
    const updatedService = { ...service, checked: !service.checked };

    const certificateServices = updateItemInArray(
      state.certificate?.services,
      updatedService,
      service.id
    );
    setState({
      ...state,
      certificate: {
        ...state.certificate,
        services: certificateServices,
      },
    });
  };

  return (
    <>
      <Collapse in={!state.isOpen}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Button
              onClick={onOpen}
              className={classes.mainButtons}
              fullWidth
              variant="outlined"
            >
              {t('Use Certificate')}
            </Button>

            {!!isServicesExists && (
              <Button
                onClick={onOpenBuyServiceDialog}
                className={classes.mainButtons}
                fullWidth
                color="primary"
              >
                {t('Buy New Service')}
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={6}
          >
            <CardNumbers {...props} />
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={state.isOpen}>
        <form onSubmit={onSubmitForm}>
          <Autocomplete
            label={t('Certificate Code')}
            name="certificate"
            getOptionSelected={isAutocompleteOptionSelected}
            getRequestBody={getCertificatesRequestBody}
            searchUrl={certificates_url}
            getOptionLabel={getAutocompleteOptionLabel}
            onChange={onAutoCompleteChange}
            method="get"
            apiName="data"
          />

          {state.certificate.services?.length > 0 && (
            <FormControl
              className={classes.servicesFormGroup}
              label={t('Services')}
            >
              {state.certificate.services.map((service: ServiceProps) => (
                <Certificate
                  key={service.id}
                  service={service}
                  classes={classes}
                  onCertificateCheckedToggle={onServiceCheck}
                />
              ))}
            </FormControl>
          )}

          <Button
            className={classes.actionButton}
            onClick={onClose}
          >
            {t('Close')}
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            type="submit"
            disabled={isFormDisabled}
          >
            {t('Use сertificate services')}
          </Button>
        </form>
        {!!isServicesExists && (
          <BuyServiceDialog
            ref={buyServiceDialog}
            {...props}
          />
        )}
      </Collapse>
    </>
  );
};

export default BuyThings;